import { connect, ConnectOptions, LocalTrack, RemoteParticipant, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { TwilioVideoServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable({
    providedIn: 'root',
})
export class VideoChatService {
    $roomsUpdated: Observable<boolean>;
    participantDisconnectedSubject = new Subject<RemoteParticipant>();
    participantConnectedSubject = new ReplaySubject<RemoteParticipant>();
    clientDisconnectedSubject = new Subject<LocalTrack>();
    dominantSpeakerChangedSubject = new Subject<RemoteParticipant>();

    private roomBroadcast = new ReplaySubject<boolean>();

    constructor(private readonly http: HttpClient, private twilioVideoService: TwilioVideoServiceProxy) {}

    async joinOrCreateRoom(name: string, tracks?: LocalTrack[]) {
        let room: Room = null;
        let connectOptions: ConnectOptions = { name: name, dominantSpeaker: true };
        if (tracks != null) connectOptions.tracks = tracks;
        try {
            const token = await this.twilioVideoService.getTwilioJwt().toPromise();

            room = await connect(token, connectOptions);
        } finally {
            if (room) {
                this.registerRoomEvents(room);
                this.roomBroadcast.next(true);
            }
        }

        return room;
    }

    nudge() {
        this.roomBroadcast.next(true);
    }

    private registerRoomEvents(room: Room) {

        // Se la Room è già stata iniziata da qualcun altro, io che mi sto connettendo in questo momento verifico che,
        // se c'è qualcuno già connesso, mi sottoscrivo alle sue tracce audio/video da visualizzare nel relativo Component adibito a quel partecipante
        room.participants.forEach((participant) => {
            this.participantConnectedSubject.next(participant);
        })

        room.on('disconnected', (thisRoom: Room) => thisRoom.localParticipant.tracks.forEach((publication) => this.clientDisconnectedSubject.next(publication.track)))
            .on('participantConnected', (participant: RemoteParticipant) => {
                this.participantConnectedSubject.next(participant);
            })
            .on('participantDisconnected', (participant: RemoteParticipant) => {
                this.participantDisconnectedSubject.next(participant);
            })
            .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) => this.dominantSpeakerChangedSubject.next(dominantSpeaker));
    }
}

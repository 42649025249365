import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

import { RenderingState } from '@shared/models/enum-caa-state';
import { CAATypeDto, ExerciseDto, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { QuizSnapShotModel } from './quiz-snapshot.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'quiz-caa',
    templateUrl: './quiz-caa.component.html',
    styleUrls: ['./quiz-caa.component.css'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class QuizCaaComponent implements OnChanges {
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Input() isOperator: boolean;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();

    stepCompleted = [];
    states = RenderingState;
    snapShotObj: QuizSnapShotModel = new QuizSnapShotModel();
    stepperIndex = 0;
    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {

        if (this.exerciseExtendedDto?.isDone) {
            this.snapShotObj = JSON.parse(this.exerciseExtendedDto?.executedJson) as QuizSnapShotModel;
        }

        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj.questions = this.snapShotObj.structureToEmptySnapShot(this.caaType.baseStructureJson);
                }
                break;
            case RenderingState.edit:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                }
                break;
            case RenderingState.executing:
                {
                    this.snapShotObj =
                        this.exerciseExtendedDto.executedJson != null ? JSON.parse(this.exerciseExtendedDto.executedJson) : JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.createStepperValidation(this.snapShotObj);
                }
                break;
        }
    }

    createStepperValidation(snapshot: QuizSnapShotModel) {
        this.stepCompleted = [];
        this.stepperIndex = this.snapShotObj.selectedIndex ?? this.stepperIndex;
        this.snapShotObj.selectedIndex = this.stepperIndex;
        let selected;
        for (let i = 0; i < snapshot.questions.length; i++) {
            selected = false;
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < snapshot.questions[i].answers.length; j++) {
                if (snapshot.questions[i].answers[j].isCorrect && snapshot.questions[i].answers[j].isSelected) {
                    selected = true;
                    this.stepCompleted[i] = true;
                    this.stepperIndex = i + 1;
                }
            }
            if (!selected) {
                this.stepCompleted[i] = false;
            }
        }
        this.changeDetector.detectChanges();
    }

    save() {
        this.modelCaa = this.modelCaa ?? new ModelCAADto();
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }

    choosedAnswer(i, j, elemRadio: MatRadioButton, stepper: MatStepper) {
        if (this.snapShotObj.questions[i].answers[j].isCorrect) {
            this.stepperIndex++;
            stepper.selectedIndex = this.stepperIndex;
            this.snapShotObj.selectedIndex = this.stepperIndex;

            this.snapShotObj.questions[i].answers[j].isSelected = true;
            this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapShotObj));
            elemRadio.color = 'primary';
            this.stepCompleted[i] = true;
            stepper.next();

            // if (this.snapShotObj.questions.length) this.notifyExerciseCompletedEvent.emit(this.snapShotObj);
        } else {
            elemRadio.color = 'warn';
            elemRadio.checked = false;
        }
    }

    choosedRightAnswer(i, j) {
        this.snapShotObj.questions[i].answers.forEach((el) => (el.isCorrect = false));
        this.snapShotObj.questions[i].answers[j].isCorrect = true;
    }
}
